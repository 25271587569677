import {Component, Vue} from 'vue-property-decorator';

@Component
export default class UsernamePrepareMixin extends Vue {
  getPreparedUsername(username: string): string {
    let prepared = username;
    // replace spaces with hyphens, trim trailing hyphens and remove duplicated hyphens
    prepared = prepared.replaceAll(' ', '-').replace(/^-+|-+$/g, '');
    prepared = prepared.replace(/([-])\1+/g, (str, match) => {
      return match[0];
    });
    return prepared;
  }
}
