










































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Vuelidate from 'vuelidate';
import {maxLength} from 'vuelidate/lib/validators';
import BhInput from '@/components/BhInput.vue';
import {vxm} from '@/store';

Vue.use(Vuelidate);

@Component({
  components: {BhInput},
  validations: {
    otherWantToSee: {maxLength: maxLength(25)},
  },
})
export default class ProfileWatchPreferences extends Vue {
  @Prop({
    type: String,
    default: 'I want to see',
  })
  readonly title!: string;
  @Prop({type: Object, required: true}) readonly wantToSeeEnum!: {[key: string]: {[key: string]: string}};
  @Prop({type: Boolean, default: false}) readonly isUpdating!: boolean;

  wantToSeeOptions = {
    all: 'all',
    inCloth: 'inCloth',
    withoutCloth: 'withoutCloth',
  };
  wantToSeeType = 'all';
  wantToSee = {} as {[key: string]: {[key: string]: boolean}};
  otherWantToSee = '';

  get otherWantToSeeErrorMessage(): string {
    return this.otherWantToSeeError
      ? 'This field should not be empty'
      : `This field should be up to ${this.$v.otherWantToSee?.$params.maxLength.max} symbols long`;
  }
  get otherWantToSeeError(): boolean {
    return (
      this.wantToSeeType === this.wantToSeeOptions.inCloth &&
      this.wantToSee.inCloth &&
      this.wantToSee.inCloth.other &&
      !this.otherWantToSee
    );
  }

  @Watch('wantToSeeType')
  onTypeChanged() {
    this.passUpdatedPreferences();
  }
  @Watch('wantToSee', {deep: true})
  onBoxesChanged() {
    this.passUpdatedPreferences();
  }
  @Watch('otherWantToSee')
  onOtherChanged() {
    this.passUpdatedPreferences();
  }

  created() {
    this.wantToSee = JSON.parse(JSON.stringify(this.wantToSeeEnum));
    Object.keys(this.wantToSee).forEach((key) => {
      if (key === this.wantToSeeOptions.inCloth || key === this.wantToSeeOptions.withoutCloth) {
        Object.keys(this.wantToSee[key]).forEach((subKey) => {
          this.wantToSee[key][subKey] = false;
        });
      }
    });
    if (this.isUpdating) {
      const wantToSeeKeys = Object.keys(vxm.user.data.wantToSee);
      if (vxm.user.data.wantToSee && wantToSeeKeys.length) {
        if (wantToSeeKeys.length > 1) {
          this.wantToSeeType = this.wantToSeeOptions.all;
        } else {
          if (wantToSeeKeys[0] === this.wantToSeeOptions.inCloth) {
            this.wantToSeeType = this.wantToSeeOptions.inCloth;
          } else if (wantToSeeKeys[0] === this.wantToSeeOptions.withoutCloth) {
            this.wantToSeeType = this.wantToSeeOptions.withoutCloth;
          }
          const enumKeys = Object.keys(this.wantToSeeEnum[this.wantToSeeType]);
          vxm.user.data.wantToSee[this.wantToSeeType].forEach((value: string) => {
            const keyByValue = enumKeys.find((enumKey) => this.wantToSeeEnum[this.wantToSeeType][enumKey] === value);
            if (keyByValue) {
              this.wantToSee[this.wantToSeeType][keyByValue] = true;
            }
          });
        }
      }
      if (vxm.user.data.otherWantToSee && typeof vxm.user.data.otherWantToSee === 'string') {
        this.otherWantToSee = vxm.user.data.otherWantToSee;
      }
    }
  }

  getFormattedValue(key: string): string {
    if (key === 'inCloth') {
      return 'With clothing';
    }
    if (key === 'withoutCloth') {
      return 'Without clothing (nudity/sex)';
    }
    return key;
  }
  passUpdatedPreferences(): void {
    const wantToSee = {} as {[key: string]: undefined | string[]};

    if (this.wantToSeeType && this.wantToSeeType !== this.wantToSeeOptions.all) {
      const wantToSeeType = this.wantToSeeType;
      if (wantToSeeType === this.wantToSeeOptions.inCloth || wantToSeeType === this.wantToSeeOptions.withoutCloth) {
        wantToSee[wantToSeeType] = [] as string[];
        Object.keys(this.wantToSee[wantToSeeType]).forEach((key) => {
          if (this.wantToSee[wantToSeeType][key]) {
            wantToSee[wantToSeeType]!.push(this.wantToSeeEnum[wantToSeeType][key]);
          }
        });
      }
    }

    const params = {
      wantToSee,
      ...(this.wantToSee.inCloth.other && {otherWantToSee: this.otherWantToSee}),
      gotErrors: false,
    };
    params.gotErrors = this.gotErrors(params);
    this.$emit('profileWatchPreferencesUpdated', params);
  }
  gotErrors(params: {otherWantToSee?: string; wantToSee: {[key: string]: string[] | undefined}}): boolean {
    return !!(
      !this.wantToSeeType ||
      (params.wantToSee.inCloth && !params.wantToSee.inCloth.length) ||
      (params.wantToSee.withoutCloth && !params.wantToSee.withoutCloth.length) ||
      (params.wantToSee.inCloth?.includes(this.wantToSeeEnum.inCloth.other) &&
        (this.$v.otherWantToSee.$error || this.otherWantToSeeError))
    );
  }
}
