import { render, staticRenderFns } from "./rollerPickerBox.vue?vue&type=template&id=dbbe2258&scoped=true&lang=pug&"
import script from "./rollerPickerBox.vue?vue&type=script&lang=ts&"
export * from "./rollerPickerBox.vue?vue&type=script&lang=ts&"
import style0 from "./rollerPickerBox.vue?vue&type=style&index=0&id=dbbe2258&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbbe2258",
  null
  
)

export default component.exports