

















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import RollerPicker from '@/components/rollerPicker/rollerPicker.vue';
import moment from 'moment/moment';
import RollerPickerBox from '@/components/rollerPicker/rollerPickerBox.vue';

@Component({components: {RollerPickerBox, RollerPicker}})
export default class MobileDatePicker extends Vue {
  @Prop({default: moment().format('YYYY-MM-DD')}) readonly value!: string;
  @Prop({default: 'MM-DD-YYYY'}) readonly placeholder!: string;
  yearIndex = 19;
  monthIndex = 0;
  dayIndex = 0;
  showPicker = false;
  monthList = [
    {value: 1, text: 'January'},
    {value: 2, text: 'February'},
    {value: 3, text: 'March'},
    {value: 4, text: 'April'},
    {value: 5, text: 'May'},
    {value: 6, text: 'June'},
    {value: 7, text: 'July'},
    {value: 8, text: 'August'},
    {value: 9, text: 'September'},
    {value: 10, text: 'October'},
    {value: 11, text: 'November'},
    {value: 12, text: 'December'},
  ];

  @Watch('value') onValueUpdated() {
    this.presetPicker();
  }

  get formatDate() {
    return this.value ? moment(this.value).format('MM-DD-YYYY') : this.placeholder;
  }

  get yearList() {
    const crtYear = moment().year();
    const arr = [];
    for (let i = crtYear - 100; i <= crtYear; i++) {
      arr.unshift({text: i, value: i});
    }
    return arr;
  }

  get dayList() {
    const d = new Date(this.yearList[this.yearIndex].value, this.monthList[this.monthIndex].value, 0);
    const arr = [];
    for (let i = 1; i <= d.getDate(); i++) {
      arr.push({text: i, value: i});
    }
    if (!arr[this.dayIndex]) {
      this.dayIndex = arr.length - 1;
    }
    return arr;
  }

  mounted() {
    this.presetPicker();
  }

  presetPicker() {
    if (this.value) {
      const date = moment(this.value);
      this.yearIndex = this.yearList.findIndex((year) => year.value === date.year());
      this.monthIndex = date.month();
      this.dayIndex = date.date() - 1;
    }
  }

  onYearChange(index: number) {
    this.yearIndex = index;
  }

  onMonthChange(index: number) {
    this.monthIndex = index;
  }

  onDayChange(index: number) {
    this.dayIndex = index;
  }

  showHidePicker() {
    if (this.showPicker) {
      this.presetPicker();
    }
    this.showPicker = !this.showPicker;
  }

  submitChange() {
    const year = this.yearList[this.yearIndex].value;
    const month = this.monthList[this.monthIndex].value;
    const day = this.dayList[this.dayIndex].value;
    this.$emit('input', moment(`${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`).toDate());
    this.showPicker = false;
  }
}
