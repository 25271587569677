








import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class RollerPickerBox extends Vue {
  @Prop({default: true}) readonly layer!: boolean;
  @Prop({default: 'rgba(0,0,0,0.1)'}) readonly lineColor!: string;

  get selectLineStyle() {
    return {
      borderColor: this.lineColor,
    };
  }
}
