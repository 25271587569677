import {Component, Vue} from 'vue-property-decorator';
import VueCroppie, {VueCroppieComponent} from 'vue-croppie';
import 'croppie/croppie.css';
Vue.use(VueCroppie);

@Component
export default class CroppieMixin extends Vue {
  cropBlock = false;
  croppieImage = '';
  cropped: string | null = null;

  croppie(e: Event | DragEvent) {
    this.cropBlock = true;
    let files = {} as FileList;
    if (e instanceof Event) {
      files = (e.target as HTMLInputElement).files as FileList;
    } else {
      files = ((e as DragEvent).dataTransfer as DataTransfer).files;
    }
    if (!files.length) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      (this.$refs.croppieRef as typeof VueCroppieComponent).bind({
        url: e.target?.result,
      });
    };
    reader.readAsDataURL(files[0]);
  }
  crop(width = 280, height = 280) {
    this.cropBlock = false;
    const options = {
      type: 'base64',
      size: {width, height},
      format: 'jpeg',
    };
    return new Promise((resolve) => {
      (this.$refs.croppieRef as typeof VueCroppieComponent).result(options, (output: string) => {
        this.cropped = this.croppieImage = output;
        resolve();
      });
    });
  }
}
